import {
  COMPANY_DETAILS_REQUEST,
  COMPANY_DETAILS_REQUEST_FAIL,
  COMPANY_DETAILS_REQUEST_SUCCESS,
  COMPANY_DETAILS_SAVE_FAIL,
  COMPANY_DETAILS_SAVE_REQUEST,
  COMPANY_DETAILS_SAVE_SUCCESS,
} from '../Constants/companyDetailsConstant';

import AxiosApi from '../../config/axios-api';

const companyDetailsAction = (companyId) => async (dispatch) => {
  try {
    dispatch({ type: COMPANY_DETAILS_REQUEST, payload: companyId });
    const { data } = await AxiosApi.get('company/get-company-details/' + companyId);
    dispatch({ type: COMPANY_DETAILS_REQUEST_SUCCESS, payload: data.result });
  } catch (error) {
    dispatch({ type: COMPANY_DETAILS_REQUEST_FAIL, payload: error.message });
  }
};

const companyDetailsSaveAction = (companyData) => async (dispatch) => {
  try {
    dispatch({ type: COMPANY_DETAILS_SAVE_REQUEST, payload: companyData });
    AxiosApi.defaults.headers.put['Content-Type'] = 'application/json';
    const { data } = await AxiosApi.put('company/update-company-details', companyData);
    dispatch({ type: COMPANY_DETAILS_SAVE_SUCCESS, payload: data.result });
  } catch (error) {
    dispatch({ type: COMPANY_DETAILS_SAVE_FAIL, payload: error.message });
  }
};

const getTeamMembersAction = () => async () => {
  try {
    const { data } = await AxiosApi.get('/user/team-members');
    return data.result.teamMemberData;
  } catch (error) {
    console.log(error);
  }
};

const invitedUserDetailAction = (refcode) => async () => {
  try {
    const { data } = await AxiosApi.get(`/company/invited-user-details`, {
      params: {
        refcode,
      },
    });

    return data.result;
  } catch (error) {
    console.log(error);
  }
};

const sendUserInvitesAction = (invitedUsers) => async () => {
  try {
    const response = await AxiosApi.post('/company/invite-users', invitedUsers);
    return response;
  } catch (error) {
    console.log(error);
  }
};

const updateUserRoleAction = (email, role) => async () => {
  try {
    const response = await AxiosApi.patch('/user/update-role', { email, role });
    return response;
  } catch (error) {
    console.log(error);
  }
};

const removeTeamMemberAction =
  (id, email, invited, mailboxesForDelete, mailboxesForTransfer) => async () => {
    try {
      const response = await AxiosApi.delete(`/user/remove-team-member`, {
        data: {
          id,
          email,
          invited,
          mailboxesForDelete,
          mailboxesForTransfer,
        },
      });
      return response;
    } catch (error) {
      console.log(error);
    }
  };

export {
  companyDetailsAction,
  companyDetailsSaveAction,
  getTeamMembersAction,
  invitedUserDetailAction,
  sendUserInvitesAction,
  updateUserRoleAction,
  removeTeamMemberAction,
};
